var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CContainer",
    { staticClass: "content-center min-vh-100" },
    [
      _c(
        "CRow",
        { staticClass: "justify-content-center" },
        [
          _c(
            "CCol",
            [
              _c(
                "CCard",
                { staticClass: "p-4" },
                [
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CForm",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.handle($event)
                            }
                          }
                        },
                        [
                          _c("h1", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value: "pages.auth.ResetPassword.title",
                                expression: "'pages.auth.ResetPassword.title'"
                              }
                            ]
                          }),
                          _c("div", { staticClass: "form-group" }, [
                            _vm.message
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "alert alert-danger",
                                    attrs: { role: "alert" }
                                  },
                                  [_vm._v(" " + _vm._s(_vm.message) + " ")]
                                )
                              : _vm._e()
                          ]),
                          _c("ValidatedInput", {
                            attrs: {
                              translationKey: "registerCustomer.password",
                              field: _vm.$v.user.password,
                              value: _vm.$v.user.password.$model,
                              autocomplete: "new-password",
                              type: "password",
                              "data-cy": "password"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.user.password,
                                  "$model",
                                  $event
                                )
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-content",
                                fn: function() {
                                  return [
                                    _c("CIcon", {
                                      attrs: { name: "cil-lock-locked" }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          }),
                          _c("ValidatedInput", {
                            attrs: {
                              translationKey:
                                "registerCustomer.passwordConfirmation",
                              field: _vm.$v.user.passwordConfirmation,
                              value: _vm.$v.user.passwordConfirmation.$model,
                              autocomplete: "new-password",
                              type: "password",
                              "data-cy": "passwordConfirmation"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.user.passwordConfirmation,
                                  "$model",
                                  $event
                                )
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-content",
                                fn: function() {
                                  return [
                                    _c("CIcon", {
                                      attrs: { name: "cil-lock-locked" }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          }),
                          _c(
                            "CButton",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                color: "primary",
                                disabled: _vm.loading || _vm.invalid,
                                type: "submit"
                              }
                            },
                            [
                              _c("span", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.loading,
                                    expression: "loading"
                                  }
                                ],
                                staticClass: "spinner-border spinner-border-sm"
                              }),
                              _c("span", {
                                directives: [
                                  {
                                    name: "t",
                                    rawName: "v-t",
                                    value: "pages.auth.ResetPassword.button",
                                    expression:
                                      "'pages.auth.ResetPassword.button'"
                                  }
                                ]
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }